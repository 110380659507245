import { default as chronologyTioVYXjkrYMeta } from "/var/www/sakhfood-admin.dev.sitisit.ru/frontend/sakhfood-admin-front-20240914034141/pages/chronology.vue?macro=true";
import { default as helpMexi8gTVWHMeta } from "/var/www/sakhfood-admin.dev.sitisit.ru/frontend/sakhfood-admin-front-20240914034141/pages/help.vue?macro=true";
import { default as history4GEkaJCRAwMeta } from "/var/www/sakhfood-admin.dev.sitisit.ru/frontend/sakhfood-admin-front-20240914034141/pages/history.vue?macro=true";
import { default as indexB5Ba5b0HA6Meta } from "/var/www/sakhfood-admin.dev.sitisit.ru/frontend/sakhfood-admin-front-20240914034141/pages/index.vue?macro=true";
import { default as menuRSQawjhPAJMeta } from "/var/www/sakhfood-admin.dev.sitisit.ru/frontend/sakhfood-admin-front-20240914034141/pages/menu.vue?macro=true";
import { default as reportsSSJfQsDldeMeta } from "/var/www/sakhfood-admin.dev.sitisit.ru/frontend/sakhfood-admin-front-20240914034141/pages/reports.vue?macro=true";
import { default as reviewsRJNNvedQQYMeta } from "/var/www/sakhfood-admin.dev.sitisit.ru/frontend/sakhfood-admin-front-20240914034141/pages/reviews.vue?macro=true";
import { default as settingsC8R60F6cQrMeta } from "/var/www/sakhfood-admin.dev.sitisit.ru/frontend/sakhfood-admin-front-20240914034141/pages/settings.vue?macro=true";
export default [
  {
    name: chronologyTioVYXjkrYMeta?.name ?? "chronology",
    path: chronologyTioVYXjkrYMeta?.path ?? "/chronology",
    meta: chronologyTioVYXjkrYMeta || {},
    alias: chronologyTioVYXjkrYMeta?.alias || [],
    redirect: chronologyTioVYXjkrYMeta?.redirect,
    component: () => import("/var/www/sakhfood-admin.dev.sitisit.ru/frontend/sakhfood-admin-front-20240914034141/pages/chronology.vue").then(m => m.default || m)
  },
  {
    name: helpMexi8gTVWHMeta?.name ?? "help",
    path: helpMexi8gTVWHMeta?.path ?? "/help",
    meta: helpMexi8gTVWHMeta || {},
    alias: helpMexi8gTVWHMeta?.alias || [],
    redirect: helpMexi8gTVWHMeta?.redirect,
    component: () => import("/var/www/sakhfood-admin.dev.sitisit.ru/frontend/sakhfood-admin-front-20240914034141/pages/help.vue").then(m => m.default || m)
  },
  {
    name: history4GEkaJCRAwMeta?.name ?? "history",
    path: history4GEkaJCRAwMeta?.path ?? "/history",
    meta: history4GEkaJCRAwMeta || {},
    alias: history4GEkaJCRAwMeta?.alias || [],
    redirect: history4GEkaJCRAwMeta?.redirect,
    component: () => import("/var/www/sakhfood-admin.dev.sitisit.ru/frontend/sakhfood-admin-front-20240914034141/pages/history.vue").then(m => m.default || m)
  },
  {
    name: indexB5Ba5b0HA6Meta?.name ?? "index",
    path: indexB5Ba5b0HA6Meta?.path ?? "/",
    meta: indexB5Ba5b0HA6Meta || {},
    alias: indexB5Ba5b0HA6Meta?.alias || [],
    redirect: indexB5Ba5b0HA6Meta?.redirect,
    component: () => import("/var/www/sakhfood-admin.dev.sitisit.ru/frontend/sakhfood-admin-front-20240914034141/pages/index.vue").then(m => m.default || m)
  },
  {
    name: menuRSQawjhPAJMeta?.name ?? "menu",
    path: menuRSQawjhPAJMeta?.path ?? "/menu",
    meta: menuRSQawjhPAJMeta || {},
    alias: menuRSQawjhPAJMeta?.alias || [],
    redirect: menuRSQawjhPAJMeta?.redirect,
    component: () => import("/var/www/sakhfood-admin.dev.sitisit.ru/frontend/sakhfood-admin-front-20240914034141/pages/menu.vue").then(m => m.default || m)
  },
  {
    name: reportsSSJfQsDldeMeta?.name ?? "reports",
    path: reportsSSJfQsDldeMeta?.path ?? "/reports",
    meta: reportsSSJfQsDldeMeta || {},
    alias: reportsSSJfQsDldeMeta?.alias || [],
    redirect: reportsSSJfQsDldeMeta?.redirect,
    component: () => import("/var/www/sakhfood-admin.dev.sitisit.ru/frontend/sakhfood-admin-front-20240914034141/pages/reports.vue").then(m => m.default || m)
  },
  {
    name: reviewsRJNNvedQQYMeta?.name ?? "reviews",
    path: reviewsRJNNvedQQYMeta?.path ?? "/reviews",
    meta: reviewsRJNNvedQQYMeta || {},
    alias: reviewsRJNNvedQQYMeta?.alias || [],
    redirect: reviewsRJNNvedQQYMeta?.redirect,
    component: () => import("/var/www/sakhfood-admin.dev.sitisit.ru/frontend/sakhfood-admin-front-20240914034141/pages/reviews.vue").then(m => m.default || m)
  },
  {
    name: settingsC8R60F6cQrMeta?.name ?? "settings",
    path: settingsC8R60F6cQrMeta?.path ?? "/settings",
    meta: settingsC8R60F6cQrMeta || {},
    alias: settingsC8R60F6cQrMeta?.alias || [],
    redirect: settingsC8R60F6cQrMeta?.redirect,
    component: () => import("/var/www/sakhfood-admin.dev.sitisit.ru/frontend/sakhfood-admin-front-20240914034141/pages/settings.vue").then(m => m.default || m)
  }
]