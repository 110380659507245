<template>
  <div
    :class="modalOverlayClass"
    @mouseover.self="state = 'wait'"
    @mouseout.self="state = 'idle'"
    @mousedown.left.self="state = 'down'"
    @mouseup.left.self="state == 'down' && closeModal()"
  >
    <div :class="modalWrapperClass">
      <!-- Modal content -->
      <div
        v-bind="$attrs"
        :class="modalClass"
      >
        <!-- <button
          type="button"
          :class="modalCloseClass"
          @click="closeModal"
        >
          <slot name="close-icon">
            <span class="i-custom:x"></span>
          </slot>
        </button> -->
        <slot />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
type ModalSizes = 'md'
type ModalColors = 'white'
type ModalPositions = 'center' | 'right' | 'bottom'
interface PropsUI {
  root?: Partial<Record<'base' | 'rounded' | 'color' | 'size' | 'padding' | 'addition', string>>
  wrapper?: Partial<Record<'base' | 'addition' | 'position', string>>
  overlay?: Partial<Record<'base' | 'addition', string>>,
  close?:Partial<Record<'base' | 'addition' | 'position' | 'size' | 'color' | 'rounded', string>>,
}

defineOptions({
  inheritAttrs: false
});

const emit = defineEmits(['close']);

const state = ref('idle');

const props = defineProps({
  size: { type: String as PropType<ModalSizes>, default: 'md' },
  closeClass: { type: String },
  ui: Object as PropType<PropsUI>,
  color: { type: String as PropType<ModalColors>, default: 'white' },
  position: { type: String as PropType<ModalPositions>, default: 'center' }
});

const UI = {
  root: {
    base: 'relative w-full pointer-events-auto',
    rounded: {
      md: 'rounded-5'
    },
    color: {
      white: 'bg-white ring-1 ring-#f1f1f1 ring-inset lg:shadow-[0_6px_18px_0] shadow-black/16'
    },
    size: {
      md: ''
    },
    padding: {
      md: 'p-5 lg:p-10'
    }
  },
  wrapper: {
    base: 'z-50 min-h-screen pointer-events-none flex w-full',
    position: {
      center: 'justify-center items-center py-6',
      right: 'justify-end items-center',
      bottom: 'justify-center items-end'
    }
  },
  overlay: {
    base: 'bg-#23261a/50 fixed z-70 overflow-y-auto top-0 left-0 w-full h-screen'
  },
  close: {
    base: 'flex items-center justify-center transition-colors',
    position: 'absolute -top-2.5 max-lg:left-1/2 -translate-x-1/2 -translate-y-full lg:top-0 lg:-right-2 lg:translate-x-full lg:translate-y-none',
    size: 'size-9 lg:size-12 text-3.5',
    color: 'bg-white/60 hover:bg-white hover:text-firmennyy',
    rounded: 'rounded-full'
  }
};

const modalOverlayClass = computed(() => [
  props?.ui?.overlay?.base ?? UI.overlay.base,
  props?.ui?.overlay?.addition ?? ''
]);

const modalClass = computed(() => [
  props?.ui?.root?.base ?? UI.root.base,
  props?.ui?.root?.rounded ?? UI.root.rounded[props.size],
  props?.ui?.root?.color ?? UI.root.color[props.color],
  props?.ui?.root?.size ?? UI.root.size[props.size],
  props?.ui?.root?.padding ?? UI.root.padding[props.size],
  props?.ui?.root?.addition ?? ''
]);

const modalWrapperClass = computed(() => [
  props?.ui?.wrapper?.base ?? UI.wrapper.base,
  props?.ui?.wrapper?.position ?? UI.wrapper.position[props.position],
  props?.ui?.wrapper?.addition ?? ''
]);

const modalCloseClass = computed(() => [
  props?.ui?.close?.base ?? UI.close.base,
  props?.ui?.close?.color ?? UI.close.color,
  props?.ui?.close?.position ?? UI.close?.position,
  props?.ui?.close?.rounded ?? UI.close?.rounded,
  props?.ui?.close?.size ?? UI.close?.size,
  props?.ui?.close?.addition ?? ''
]);

const closeModal = () => {
  emit('close');
};

onMounted(async () => {
  await nextTick();
  if (document) {
    document.body.style.overflow = 'hidden';
  }
});

onUnmounted(async () => {
  await nextTick();

  if (document) {
    document.body.style.overflow = '';
  }
});
</script>
