<template>
  <div>
    <NuxtLoadingIndicator color="#FFC115" />
    <main class="grid grid-cols-[266px_1fr] gap-7.5 h-screen">
      <Sidebar class="h-full" />
      <div class="pt-7.5 pr-7.5">
        <NuxtPage />
      </div>
    </main>
    <TransitionModal>
      <AuthenticationModal v-if="!isLoggedIn" />
    </TransitionModal>
  </div>
</template>

<script setup lang="ts">

import { useUserAuth } from '~/composables/user/useUserAuth';
import { useRestaurants } from '~/composables/partner/restaurants/useRestaurants';
import { UseSiteSettings } from './composables/site-settings/useSiteSettings';

const { getSiteSettings } = UseSiteSettings();

const { userLogin, getUserMe, isLoggedIn } = useUserAuth();

async function test () {
  // const res = await userLogin({password:"q12345678",phone:'79193654711'});
  // const res = await userLogin({password:"Q1W2E3R4T5",phone:'79991234567'});
  const user = await getUserMe();
  await getSiteSettings();

  console.log(user, 'getUserMe');
}

const { restaurantList: restaurants } = useRestaurants();

onMounted(async () => {
  await test();
});
</script>
