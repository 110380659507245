import { orderStore as useOrderStore } from '~/stores/order-store';
import { restaurantStore as useRestaurantStore } from '~/stores/restaurants-store'; // Импортируйте store ресторана
import type { OrderDataType } from '~/composables/partner/orders/types/PartnerOrdersTypes';
import { useNuxtApp, onMounted, watch } from '#imports';
import type { ServerResponseCommonType } from '~/types/commons';

type WebSocketMessage = {
    event: string;
    data: any;
    channel?: string;
};

type ConnectionEstablishedData = {
    socket_id: string;
    activity_timeout: number;
};

type AuthResponse = {
    auth: string;
};

type SocketOrder = {
  order: OrderDataType
}

export default defineNuxtPlugin((nuxtApp) => {
  const orderStore = useOrderStore();
  const restaurantStore = useRestaurantStore(); // Используйте store ресторана
  const authBearer = useCookie('authToken');
  let socket: WebSocket;
  let authToken: string | null = null;
  let pingInterval: NodeJS.Timeout | null = null;

  const config = useRuntimeConfig();
  const { WebSocket } = globalThis;

  const initializeSocket = async (restaurantId: string) => {
    if (socket) {
      if (authToken) {
        await unsubscribeFromChannel(authToken, 'private-orders.' + restaurantId);
      }
      socket.close();
      if (pingInterval) {
        clearInterval(pingInterval);
      }
    }
    // @ts-ignore
    socket = new WebSocket(config.public.websoketURL);

    socket.onopen = (event: Event) => {
      console.log('Соединение установлено');

      // Установка интервала для отправки пинга каждые 30 секунд
      pingInterval = setInterval(() => {
        socket.send(JSON.stringify({ event: 'pusher:ping' }));
        console.log('Пинг отправлен');
      }, 15000); // 30000 миллисекунд = 30 секунд
    };

    socket.onmessage = async (event: MessageEvent) => {
      const message: WebSocketMessage = JSON.parse(event.data);

      if (message.event === 'pusher:connection_established') {
        const data: ConnectionEstablishedData = JSON.parse(message.data);
        const { socket_id } = data;
        console.log('Socket ID:', socket_id);

        try {
          authToken = await getWebsoketAuthToken(socket_id, 'private-orders.' + restaurantId);
          subscribeToChannel(authToken, 'private-orders.' + restaurantId);
          console.log('Установлено соединение с каналом');
        } catch (error) {
          console.error('Ошибка при получении токена:', error);
        }
      }

      if (message.event === 'OrderCreatedEvent') {
        const orderData: SocketOrder = JSON.parse(message.data);
        orderStore.addOrder(orderData);
        orderStore.addOrderToNew(orderData.order.id);
        const uvedAudio = new Audio('/sound/uved.mp3');
        uvedAudio.play();
        console.log('Order Created:', orderData);
      }

      if (message.event === 'OrderUpdatedEvent') {
        const orderData: SocketOrder = JSON.parse(message.data);
        orderStore.updateOrder(orderData);
        // const index = orderStore.newOrders.findIndex((o) => o.id === orderData.order.id);
        // if (index === -1) {
        //   orderStore.addOrderToNew(orderData);
        // }

        console.log('Order Updated:', orderData);
      }

      if (message.event === 'pusher:ping') {
        socket.send(JSON.stringify({ event: 'pusher:pong' }));
      }
    };

    socket.onclose = (event: CloseEvent) => {
      console.log('Соединение закрыто', event);
      initializeSocket(String(restaurantStore.selectedRestaurant?.id));
    };

    socket.onerror = (error: Event) => {
      console.log('Ошибка:', error);
    };
  };

  watch(() => restaurantStore.selectedRestaurant, (newRestaurant, oldRestaurant) => {
    if (newRestaurant) {
      initializeSocket(String(newRestaurant.id));
    }
  }, { immediate: true });

  async function getWebsoketAuthToken (socket_id: string, channel_name: string): Promise<string> {
    const formData = new URLSearchParams();
    formData.append('socket_id', socket_id);
    formData.append('channel_name', channel_name);
    const response = await fetch(`${config.public.baseApiUrl}/broadcasting/auth`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${authBearer.value}`,
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: formData.toString()
    });

    if (!response.ok) {
      throw new Error(`Ошибка HTTP: ${response.status}`);
    }

    const data: AuthResponse = await response.json();
    return data.auth;
  }

  function subscribeToChannel (auth: string, channel: string): void {
    console.log('starting sub');
    console.log(auth, channel);
    const subscriptionMessage = {
      event: 'pusher:subscribe',
      data: {
        auth,
        channel
      }
    };

    socket.send(JSON.stringify(subscriptionMessage));
  }

  async function unsubscribeFromChannel (auth: string | null, channel: string): Promise<void> {
    if (!auth) { return; }

    console.log('starting unsub');
    console.log(auth, channel);
    const unsubscriptionMessage = {
      event: 'pusher:unsubscribe',
      data: {
        auth,
        channel
      }
    };

    socket.send(JSON.stringify(unsubscriptionMessage));
  }
});
