import type { Ref } from 'vue';
import type { UserMeType } from '~/composables/user/types/UserTypes';
import { defineStore } from 'pinia';

type UserStoreType = {
  userMe: Ref<UserMeType | null>;
  isUserLoggedIn: Ref<boolean>;
  setUser: (user: UserMeType | null) => void;
}

export const userStore = defineStore('user-store', (): UserStoreType => {
  const userMe = ref<UserMeType | null>(null);
  const isUserLoggedIn = ref(false);

  const setUser = (user: UserMeType | null) => {
    if (user) {
      userMe.value = user;
      isUserLoggedIn.value = true;
    } else {
      userMe.value = null;
      isUserLoggedIn.value = false;
    }
  };

  return {
    userMe,
    isUserLoggedIn,

    setUser
  };
});
