import { defineStore } from 'pinia';
import type { RestaurantInfoType } from '~/composables/partner/restaurants/types/RestaurantsTypes';
import type { Ref } from 'vue';

type RestaurantStoreType = {
	restaurantList: Ref<RestaurantInfoType[]>;
	setRestaurantList:(restaurants: RestaurantInfoType[]) => void;
  selectedRestaurant: Ref<RestaurantInfoType |null>,
  setSelectedRestaurant:(restaurant: RestaurantInfoType) => void; 
}
export const restaurantStore = defineStore('restaurant-store', ():RestaurantStoreType => {
  const restaurantList = ref<RestaurantInfoType[]>([]);
  const selectedRestaurant = ref<RestaurantInfoType | null>(null)

  function setRestaurantList (restaurants: RestaurantInfoType[]) {
    restaurantList.value = restaurants;
  }
  function setSelectedRestaurant (restaurant: RestaurantInfoType) {
    selectedRestaurant.value = restaurant
  } 

  return {
    restaurantList,
    selectedRestaurant,
	  setRestaurantList,
    setSelectedRestaurant
  };
});
