import type { ComputedRef } from 'vue';

import { storeToRefs, computed } from '#imports';
import type { ServerResponseCommonType } from '~/types/commons';
import type { RestaurantInfoType } from './types/RestaurantsTypes';
import { restaurantStore as store } from '~/stores/restaurants-store';
// оставляю со скоупом на расширение

type TransportRestrauntDataType = {
    getUserRestaurantsList: () => Promise<RestaurantInfoType[] |null >
    restaurantList:Ref<RestaurantInfoType[]>;
    selectedRestaurant: Ref<RestaurantInfoType | null>
    setSelectedRestaurant: (restaurant: RestaurantInfoType) => void
    changeRestraunt: (body: {restaurant_id: number, is_online: 0 | 1}) => Promise<ServerResponseCommonType | null>
}

export function useRestaurants (): TransportRestrauntDataType {
  const { $api } = useNuxtApp();
  const restaurantsStore = store();
  const { restaurantList, selectedRestaurant } = storeToRefs(restaurantsStore);

  const selectedRestaurantCookie = useCookie('selected-restaurant');

  const changeRestraunt = async (body: {restaurant_id: number, is_online: 0 | 1}) => {
    const res = await $api<ServerResponseCommonType>('/partner/restaurant/change', {
      method: 'POST',
      body: { ...body }
    });
    if (res && res.message === 'Success') {
      return res;
    }
    return null;
  };
  const getUserRestaurantsList = async (): Promise<RestaurantInfoType[] | null> => {
    const res = await $api<ServerResponseCommonType<RestaurantInfoType[]>>('/partner/restaurant/getList')
      .catch((err) => { throw err; });

    if (res && res.data) {
      if (!selectedRestaurantCookie.value) {
        restaurantsStore.setSelectedRestaurant(res.data[0]);
        selectedRestaurantCookie.value = String(res.data[0].id);
      } else {
        const neededRes = res.data.find((restaurant) => restaurant.id === Number(selectedRestaurantCookie.value));
        if (neededRes) {
          restaurantsStore.setSelectedRestaurant(neededRes);
        }
      }
      restaurantsStore.setRestaurantList(res.data);
      return res.data;
    }

    return [];
  };

  const setSelectedRestaurant = (restaurant: RestaurantInfoType) => {
    restaurantsStore.setSelectedRestaurant(restaurant);
    selectedRestaurantCookie.value = String(restaurant.id);
    console.log(selectedRestaurantCookie.value);
  };

  return {
    getUserRestaurantsList,
    restaurantList,
    selectedRestaurant,
    setSelectedRestaurant,
    changeRestraunt
  };
}
