<template>
  <div
    v-on-click-outside="outsideClickHandler"
    :class="rootClass"
  >
    <slot name="header">
      <button
        type="button"
        :class="headerContentClass"
        @click="toggle"
      >
        <slot
          name="dropdown-header-content"
          :is-show="isShow"
        >
          {{ title }}
        </slot>
        <slot
          :class="headerIconClass"
          name="dropdown-header-icon"
        ></slot>
      </button>
    </slot>
    <TransitionOpacity>
      <slot
        name="dropdown-wrapper"
        :is-show="isShow"
      >
        <div
          v-show="isShow"
          :class="wrapperClass"
        >
          <slot :is-show-toggle="toggle" />
        </div>
      </slot>
    </TransitionOpacity>
  </div>
</template>

<script lang="ts" setup>
import { vOnClickOutside } from '@vueuse/components';
type UIKeys = 'root' | 'headerContent' | 'headerIcon' | 'wrapper'
const props = defineProps({
  title: { type: String, default: '' },
  ui: { type: Object as PropType<Partial<Record<UIKeys, string>>> },
  outsideClick: { type: Boolean, default: true }
});

const isShow = ref();

const ui: Record<UIKeys, string> = {
  root: '',
  headerContent: '',
  headerIcon: '',
  wrapper: ''
};

const wrapperClass = computed(() => props?.ui?.wrapper ?? ui.wrapper);
const rootClass = computed(() => props?.ui?.root ?? ui.root);
const headerContentClass = computed(() => props?.ui?.headerContent ?? ui.headerContent);
const headerIconClass = computed(() => props?.ui?.headerIcon ?? ui.headerIcon);

const toggle = () => {
  isShow.value = !isShow.value;
};

const outsideClickHandler = () => {
  if (props.outsideClick) {
    isShow.value = false;
  }
};
</script>
