import { defineStore } from 'pinia';
import { useCookie } from '#imports';
import type { OrderDataType } from '~/composables/partner/orders/types/PartnerOrdersTypes';

export const orderStore = defineStore('order-store', {
  state: () => ({
    orders: [] as OrderDataType[],
    newOrders: [] as number[]
  }),
  actions: {
    addOrder (order: {order: OrderDataType}) {
      this.orders.unshift(order.order);
    },
    updateOrder (order: {order: OrderDataType}) {
      const index = this.orders.findIndex((o) => o.id === order.order.id);
      if (index !== -1) {
        this.orders[index] = order.order;
      }
    },
    setOrders (orders: OrderDataType[]) {
      this.orders = orders;
      this.newOrders = orders
        .filter((order) => order.status.id < 3) // фильтруем по условию
        .map((order) => order.id); // получаем массив ID
    },
    addOrderToNew (id: number) {
      this.newOrders.unshift(id);
    },
    deleteOrderInNew (order: OrderDataType) {
      const index = this.newOrders.findIndex((o) => o === order.id);
      if (index !== -1) {
        this.newOrders.splice(index, 1);
      }
    },
    setOrdersInNew (orders: number[]) {
      this.newOrders = orders;
    }
    // saveNewOrdersToCookie () {
    //   const newOrdersCookie = useCookie('newOrders', {
    //     default: () => '[]'
    //   });
    //   try {
    //     // @ts-ignore
    //     newOrdersCookie.value = this.newOrders;
    //     console.log(newOrdersCookie.value);
    //   } catch (error) {
    //     console.error('Failed to stringify newOrders:', error);
    //   }
    // }
  }
});
