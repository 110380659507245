import { useUserAuth } from '~/composables/user/useUserAuth';
import { useRestaurants } from '~/composables/partner/restaurants/useRestaurants';

// тут можно загружать и подключать все что нужно инициализировать на стророе клиента при старте
export default defineNuxtPlugin(async (nuxtApp) => {
  const {
    isLoggedIn,
    getUserMe
  } = useUserAuth();

  const {getUserRestaurantsList } = useRestaurants()

  const user = await getUserMe().catch((err) => {
    console.log('load user plugin:', err.response?._data?.message);
    return null;
  });

  if (!isLoggedIn.value) {}

  await getUserRestaurantsList().catch((err) => {
    console.log('load restraunts plugin', err.response?._data?.message)});

});
